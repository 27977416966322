import self from "../img/self.png"
import mock1 from "../img/mock3.png"
import mock2 from "../img/mock2.png"

/* Hi there! Thanks for checking out my portfolio template. Be sure to read the comments to get a better understanding of
how to make this template work best for you! */

export let colors = ["rgb(0,255,164)", "rgb(166,104,255)"];
/*
I highly recommend using a gradient generator like https://gradientgenerator.paytonpierce.dev/ to generate a pair of colors that you like.
These colors will be used to style your name on the homepage, the background of your picture, and some other accents throughout
the site.
 */


/*
So let's get started! Some of the info below is pretty self-explanatory, like 'firstName' and 'bio'. I'll try to explain anything
that might not be obvious right off the bat :) I recommend looking at the template example live using "npm start" to get an idea
of what each of the values mean.
 */

export const info = {
    firstName: "Lougans",
    lastName: "deMatos",
    initials: "lougans", // the example uses first and last, but feel free to use three or more if you like.
    position: "a Full Stack Developer",
    selfPortrait: self, // don't change this unless you want to name your self-portrait in the "img" folder something else!
    gradient: `-webkit-linear-gradient(135deg, ${colors})`, // don't change this either
    baseColor: colors[0],
    miniBio: [ // these are just some "tidbits" about yourself. You can look at mine https://paytonjewell.github.io/#/ for an example if you'd like
        {
            emoji: '☕',
            text: 'fueled by coffee'
        },
        {
            emoji: '🌎',
            text: 'based in the BR'
        },
        {
            emoji: "💼",
            text: "Full developer at Grupo Euax"
        },
        {
            emoji: "📧",
            text: "lougans.dematos@outlook.com"
        }
    ],
    socials: [
        {
            link: "https://medium.com/@lougansjs/",
            icon: 'fa fa-medium',
            label: 'medium'
        },
        {
            link: "https://instagram.com/lougans.js",
            icon: 'fa fa-instagram',
            label: 'instagram'
        },
        {
            link: "https://github.com/lougansjs",
            icon: "fa fa-github",
            label: 'github'
        },
        {
            link: "https://linkedin.com/in/lougansdematos",
            icon: "fa fa-linkedin",
            label: 'linkedin'
        },
        {
            link: "https://twitter.com/lougans_matos",
            icon: "fa fa-twitter",
            label: 'twitter'
        }
// Feel free to remove any of these that you don't have. I'll write an FAQ on how to add new ones later, let me know if you have a request for an icon!
// Just change the links so that they lead to your social profiles.

    ],
    bio: "I'm developer focused on Web Development, I'm 26 years old and based in Brazil. I currently work at Grupo Euax, a business accelerator, developing solutions for Artia, Twygo e Fleeg web applications. I am passionate about almost all areas of programming, be it front-end, back-end, architecture, testing and I am always looking for knowledge and information about these topics. My current focus is architecture, mainly with regard to finding solutions for application performance and observability. If you have something interesting that helped you at some point, I'd love to hear about it too and you can send it to me via any of the social networks listed above.",
    skills:
        {
            proficientWith: ['ruby', 'rails', 'postgresql', 'typescript', 'nodejs', 'javascript', 'react', 'git', 'php', 'wordpress', 'postman', 'bootstrap', 'html5', 'css3', 'vscode', 'photoshop'],
            exposedTo: ['aws', 'python', 'docker']
        }
    ,
    hobbies: [
        {
            label: 'reading',
            emoji: '📖'
        },
        {
            label: 'theater',
            emoji: '🎭'
        },
        {
            label: 'movies',
            emoji: '🎥'
        },
        {
            label: 'cooking',
            emoji: '🌶'
        }
// Same as above, change the emojis to match / relate to your hobbies or interests.
// You can also remove the emojis if you'd like, I just think they look cute :P
    ],
    portfolio: [ // This is where your portfolio projects will be detailed
        {
            title: "Gama Aircnc",
            live: "https://gama-aircnc.now.sh/", //this should be a link to the live version of your project, think github pages, netlify, heroku, etc. Or your own domain, if you have it.
            source: "https://github.com/lougansjs/gama-aircnc", // this should be a link to the **repository** of the project, where the code is hosted.
            image: mock1
        },
        {
            title: "Covid News",
            live: "https://gama-covid-news.vercel.app/",
            source: "https://github.com/lougansjs/covid-news",
            image: mock2
        }
    ]
}